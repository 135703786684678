<template>
  <div>
    <profile-header text="Settings" />

    <v-form class="settings">
      <v-text-field label="City" :rules="[rules.required]" outlined />
      <v-text-field label="Country" :rules="[rules.required]" outlined />
      <v-text-field label="Zip Code" :rules="[rules.required]" outlined />

      <v-btn block large color="black lighten-3 white--text">
        Place Order
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader';

export default {
  components: {
    ProfileHeader
  },
  computed: {
    rules() {
      return {
        required: value => !!value || 'Required'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  width: 60%;

  max-width: 600px;
  min-width: 300px;
}
</style>